import authMiddleware from '@/middlewares/authMiddleware'
import organizationMiddleware from '@/middlewares/organizationMiddleware'
import permissionMiddleware from '@/middlewares/permissionMiddleware'
export default [
  {
    path: ":organization?/contact",
    name: "contact",
    component: () => import('pages/contacts/ContactPage.vue'),
    title: "Contact",
    redirect: { name: "list-contact" },
    children: [
      {
        path: "list",
        name: "list-contact",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          icon: "mdi-contacts-outline",
          requiredPermission: "Contact::Read",
          titleTranslationKey: "contacts.contacts-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'contacts.contacts-list',
              icon: 'mdi-contacts-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/ListContactComponent.vue')
      },
      {
        path: "create",
        name: "create-contact",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "Contact::Create",
          titleTranslationKey: "contacts.create-contact-title",
          icon: "mdi-card-account-phone-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'contacts.contacts-list',
              icon: 'mdi-contacts-outline',
              disabled: false,
              exact: true,
              routeName: 'list-contact',
            },
            {
              translationKey: 'contacts.create-contact',
              icon: 'mdi-card-account-phone-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/CreateContactComponent.vue')
      },
      {
        path: "edit/:id",
        name: "edit-contact",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "Contact::Update",
          titleTranslationKey: "contacts.edit-contact",
          icon: "mdi-card-account-details-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'contacts.contacts-list',
              icon: 'mdi-contacts-outline',
              disabled: false,
              exact: true,
              routeName: 'list-contact',
            },
            {
              translationKey: 'contacts.edit-contact',
              icon: 'mdi-card-account-details-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/EditContactComponent.vue'),
      },
      {
        path: "show/:id",
        name: "show-contact",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "Contact::Read",
          titleTranslationKey: "contacts.contact-profile",
          icon: "mdi-card-account-details-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'contacts.contacts-list',
              icon: 'mdi-contacts-outline',
              disabled: false,
              exact: true,
              routeName: 'list-contact',
            },
            {
              translationKey: 'contacts.contact-profile',
              icon: 'mdi-card-account-details-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/ShowContactComponent.vue'),
      },
      {
        path: "import",
        name: "import-contact",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          icon: "mdi-file-import-outline",
          requiredPermission: "Contact::Read",
          titleTranslationKey: "contacts.contacts-import-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'contacts.contacts-list',
              icon: 'mdi-contacts-outline',
              disabled: false,
              exact: true,
              routeName: 'list-contact',
            },
            {
              translationKey: 'contacts.import-bulk',
              icon: 'mdi-file-import-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/ImportBulkContactComponent.vue')
      },
      {
        path: "imported-list",
        name: "import-contact-list",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          icon: "mdi-database-import-outline",
          titleTranslationKey: "bulk-import.contacts-import-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'bulk-import.contacts-import-list',
              icon: 'mdi-database-import-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/ListImportBulkContactComponent.vue')
      },
      {
        path: "imported-list/details/:id",
        name: "show-contact-import-details",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          icon: "mdi-application-import",
          titleTranslationKey: "bulk-import.contacts-import-details-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'bulk-import.contacts-import-list',
              icon: 'mdi-contacts-outline',
              disabled: false,
              exact: true,
              routeName: 'import-contact-list',
            },
            {
              translationKey: 'bulk-import.contacts-import-details',
              icon: 'mdi-application-import',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/contacts/ShowImportBulkContactComponent.vue')
      },
    ]
  }
];
