import { boot } from "quasar/wrappers";
import { createI18n } from "vue-i18n";
import messages from "src/i18n";
const i18ninstance = createI18n({
  locale: "en-US",
  globalInjection: true,
  messages,
});
export default boot(({ app }) => {


  // Set i18n instance on app
  app.use(i18ninstance);
});

export const i18n = i18ninstance.global;
