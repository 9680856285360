export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import('pages/SettingsPage.vue'),
    title: "Settings",
    redirect: { name: "list-settings" },
    children: [
      {
        path: "list",
        name: "list-settings",
        meta: {
          auth: true,
          titleTranslationKey: "app.settings",
          icon: "mdi-cog-outline",
          color: "orange",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'nav-bar.settings-caption',
              icon: 'mdi-cog-outline',
              disable: true,
            },
          ],
          requiredOrganization: false,
        },
        component: () => import('@/components/settings/SettingsComponent.vue')
      },

    ]
  }
];
