import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import { LocalStorage } from 'quasar'
import { Notify } from 'quasar'

export const useOrganizationStore = defineStore('organization', {
  id: 'organization',
  state: () => ({
    myOrganization: LocalStorage.getItem('myOrganization') || null,
    loading: false,
  }),

  getters: {
  },

  actions: {
    changeOrganization(item) {
      this.myOrganization = item
      LocalStorage.set('myOrganization', item)
    },

    setMyOrganization(item) {
      const currentOrg = this.myOrganization
      if (currentOrg) return
      if (item?.user_organizations.length !== 0) {
        if (!this.myOrganization) {
          let firstAcceptedInvitation = item.user_organizations.find(
            ({ invitation_status }) => {
              return invitation_status === "Accepted";
            }
          );
          this.myOrganization = firstAcceptedInvitation || item.user_organizations[0];
          LocalStorage.set('myOrganization', this.myOrganization)
        }
      }
    },

    async declineInvitation(org) {
      this.loading = true
      try {
        await api.post(org.api_prefix + '/users/invitation/decline')
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },


    async acceptInvitation(org) {
      this.loading = true
      try {
        await api.post(org.api_prefix + '/users/invitation/accept')
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async leaveOrganization(org) {
      this.loading = true
      try {
        const { data } = await api.post(org.api_prefix + '/users/leave')
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async abortUserInvitation(user) {
      this.loading = true
      try {
        const { data } = await api.post(this.myOrganization.api_prefix + '/users/' + user.id + '/invite/abort')
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async resendInvitation(user) {
      this.loading = true
      try {
        const { data } = await api.post(this.myOrganization.api_prefix + '/users/' + user.id + '/invite')
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async removeFromOrganization(user) {
      this.loading = true
      try {
        const { data } = await api.post(this.myOrganization.api_prefix + '/users/' + user.id + '/separate')
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },
  }
})
