import authMiddleware from '@/middlewares/authMiddleware'
import organizationMiddleware from '@/middlewares/organizationMiddleware'
import permissionMiddleware from '@/middlewares/permissionMiddleware'
export default [
  {
    path: ":organization?/user",
    name: "user",
    component: () => import('pages/users/UserPage.vue'),
    title: "User",
    redirect: { name: "list-user" },
    children: [
      {
        path: "list",
        name: "list-user",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          icon: "mdi-account-group-outline",
          requiredPermission: "User::Read",
          titleTranslationKey: "users.users-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/ListUserComponent.vue')
      },
      {
        path: "create",
        name: "create-user",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "User::Create",
          titleTranslationKey: "users.invite-user-title",
          icon: "mdi-account-multiple-plus-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.invite-user',
              icon: 'mdi-account-multiple-plus-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/CreateUserComponent.vue')
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          middleware: [authMiddleware],
          titleTranslationKey: "users.user-profile",
          icon: "mdi-card-account-details-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'app.my-account',
              icon: 'mdi-card-account-details-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/UserProfileComponent.vue'),
      },
      {
        path: "edit/:id",
        name: "edit-user",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "User::Update",
          titleTranslationKey: "users.edit-user",
          icon: "mdi-account-box",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.edit-user',
              icon: 'mdi-account-box',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/EditUserComponent.vue'),
      },
      {
        path: "show/:id",
        name: "show-user",
        meta: {
          middleware: [authMiddleware, organizationMiddleware, permissionMiddleware],
          requiredPermission: "User::Read",
          titleTranslationKey: "users.user-profile",
          icon: "mdi-account-box",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.user-profile',
              icon: 'mdi-account-box',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/ShowUserComponent.vue'),
      }
    ]
  }
];
