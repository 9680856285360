import { useOrganizationStore } from 'stores/organization';
import { useUserStore } from "@/stores/user";
import { Loading, QSpinnerComment, Dark, Cookies } from 'quasar'

export default async function organizationMiddleware({ next, to, store }) {
  const organizationStore = useOrganizationStore(store);
  const org = organizationStore.myOrganization
  Loading.show({
    spinner: QSpinnerComment,
    backgroundColor: Dark.isActive ? 'dark' : 'white',
    spinnerColor: 'primary',
    spinnerSize: 140,
    messageColor: Dark.isActive ? 'white' : 'dark',
    message: '<div class="text-h5 text-uppercase">Welcome to <b>Chat Your Job</b></div><br/><div class="text-subtitle2 ">Powered by <b>DEKRA</b></div><br/><span class="text-italic">Please wait...</span>',
    html: true,
  })

  const token = Cookies.has("authToken");
  if (!token) {
    Loading.hide()
    return next({
      name: "login",
    });
  }
  if (!org) {
    Loading.hide()
    return next({ name: "no-organization" })
  }

  if (to.params?.organization === undefined) {
    Loading.hide()
    return next({
      name: to.name || 'home',
      params: {
        organization: org.api_prefix,
      },
    });
  }

  Loading.hide()
  return next();
}
