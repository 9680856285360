import { useOrganizationStore } from 'stores/organization'
import { storeToRefs } from 'pinia'

export function usePermission() {
  const orgStore = useOrganizationStore();
  const { myOrganization } = storeToRefs(orgStore)


  const userCan = (permission) => {
    if (!myOrganization.value) return false;
    const permissions = myOrganization.value.user_permissions
    if (permissions) {
      if (permissions.includes(permission)) {
        return true;
      } else if (permissions.some(perr => perr.includes(permission))) {
        return true
      }
      return false;
    }
  }

  return {
    userCan,
  };
}
